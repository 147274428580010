import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { SpeedInsights } from "@vercel/speed-insights/react"
import BirthdayPopup from './components/BirthdayPopup';
// import VideoPopup from './components/VideoPopup';

import Birthdays from './files/birthday.json';
import bakareImage from './files/bakareimage.png';
import nodeJsIcon from './files/icons/nodejs.png';
import gitIcon from './files/icons/git.png';
import typescriptIcon from './files/icons/ts.png';
import mongodbIcon from './files/icons/mongodb.png';
import mysqlIcon from './files/icons/mysql.png';
import microservicesIcon from './files/icons/microservice.png';
import redisIcon from './files/icons/redis.png';
import react from './files/icons/react.png';
import net from './files/icons/C#.png';
// import awsIcon from './files/icons/aws.png';
// import linuxIcon from './files/icons/linux.png';
import uhunger from "./files/logos/uhunger.jpeg";
import classoptima from "./files/co.jpeg";
// import statcal from "./files/logos/statcal.png";
import voting from "./files/logos/voting.png";
import mail from "./files/logos/mailer.jpeg";
import './Style.css';
import Contact from './components/Contact';

function App() {
  const projects = [
    {
      title: 'Uhunger',
      description: 'A food delivery app that allows students to order food from restaurants on campus to their dorms.',
      link: 'https://uhunger.com',
      image: uhunger
    },
    {
      title: 'ClassOptima',
      description: 'A table scheduler application built for schools to streamline the creation and management of class timetables. It helps efficiently allocate time slots and resources, ensuring conflict-free schedules with an easy-to-use interface.',
      link: 'https://github.com/bakare-dev/ClassOptima-Api',
      image: classoptima
    },
    {
      title: 'Mailer',
      description: 'An API for sending emails.',
      link: 'https://github.com/bakare-dev/hills-mailer',
      image: mail
    },
    {
      title: 'Voting System',
      description: 'Voting System used for conducting elections.',
      link: 'https://github.com/bakare-dev/lusc_voting_api',
      image: voting
    },
  ];
  
  const skills = [
    { name: 'Node.js', icon: nodeJsIcon },
    { name: 'Git', icon: gitIcon },
    { name: 'React', icon: react },
    { name: '.Net', icon: net },
    { name: 'TypeScript', icon: typescriptIcon },
    { name: 'MongoDB', icon: mongodbIcon },
    { name: 'MySQL', icon: mysqlIcon },
    { name: 'Microservices', icon: microservicesIcon },
    { name: 'Redis', icon: redisIcon },
    // { name: 'AWS', icon: awsIcon },
    // { name: 'Linux', icon: linuxIcon },
  ];
  
  const [showPopup, setShowPopup] = useState(false);
  // const [showVideo, setShowVideo] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');


  useEffect(() => {
    const makeApiCall = async () => {
      try {
        await fetch('https://bakare-mailer.onrender.com/api/v1/health');
      } catch (error) {
        console.error('API call failed', error);
      }
    };

    const checkBirthday = async () => {
      const data = Birthdays;
      const today = new Date();
      const todayDate = `${today.getDate()}th ${today.toLocaleString('default', { month: 'long' })}`;

      const birthday = data.find(item => item.date === todayDate);

      if (birthday) {
        setPopupMessage(birthday.message);
        setShowPopup(true);
      }
    };

    makeApiCall();
    checkBirthday();
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => document.querySelector('.project-list').scrollBy({ left: 200, behavior: 'smooth' }),
    onSwipedRight: () => document.querySelector('.project-list').scrollBy({ left: -200, behavior: 'smooth' }),
    trackMouse: true,
  });

  const currentYear = new Date().getFullYear();

  return (
    <div className="App">
      <header className="header">
        <nav className="nav-left">
          <a href="#projects">Projects</a>
          <a href="#contact">Contact Me</a>
        </nav>
        <div className="nav-center">
          <a href="#projects">Projects</a>
          <a href="#contact">Contact Me</a>
        </div>
        <div className="nav-right">
          <a href="https://github.com/bakare-dev" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/praise-bakare-5648971b0/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href="https://twitter.com/bakare_dev" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
      </header>

      <section className="hero">
        <span className="emoji emoji-1">💻</span>
        <span className="emoji emoji-3">🔍</span>
        <span className="emoji emoji-7">🧑‍💻</span>
        <span className="emoji emoji-8">🔒</span>
        <span className="emoji emoji-2">🚀</span>
        <span className="emoji emoji-11">🎯</span>
        <h1>Bakare Praise<img src={bakareImage} alt="cool" className="emoji-image" /></h1>
        <p className="role">Backend Engineer</p>
        <p className="resume-text">My Resume</p>
        <a href="/BAKARE PRAISE RESUME.pdf" download className="resume-button">Download</a>
      </section>

      <section id="about" className="about">
        <div className="about-wrapper">
          <img src={bakareImage} alt="Bakare Praise" className="about-image" />
          <div className="about-text-box">
            <div className="about-details">
              <h3>Bakare Praise Stephen <span role="img" aria-label="hello">👋</span></h3>
              <p>Hi there! Let's build the next big thing and make some tech magic happen!</p>
              <p>I've worked on exciting projects like E-commerce platforms, Real Estate applications, and Engineers to bring these ideas to life. Let's create something amazing together!</p>
            </div>
          </div>
        </div>
      </section>

      <section id="skills" className="skills">
        <h2>Skills</h2>
        <div className="skills-list">
          {skills.map((skill) => (
            <div key={skill.name} className="skill-item">
              <img src={skill.icon} alt={skill.name} className="skill-icon" />
              <span>{skill.name}</span>
            </div>
          ))}
        </div>
      </section>

      <section id="projects" className="projects" {...(window.innerWidth < 768 ? handlers : {})}>
        <h2 id="project-title">Projects</h2>
        <div className="project-list">
          {projects.map((project, index) => (
            <a
              key={index}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="project-item"
            >
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <img className='project-image' src={project.image} alt={project.title} />
                <p className="project-description">{project.description}</p>
              </div>
              <div className="project-overlay">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <i className="arrow-icon">→</i>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>



      <Contact/>

      <footer className="footer">
        <div className="footer-left">
          Bakare Praise | Copyright &copy; {currentYear}
        </div>
        <div className="footer-right">
          Thank you for visiting
        </div>
        <div className="footer-center">
          <p>Bakare Praise | Copyright &copy; {currentYear}</p>
          <p>Thank you for visiting</p>
        </div>
      </footer>
      <SpeedInsights />
      {showPopup && <BirthdayPopup message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
    
  );
}

export default App;
