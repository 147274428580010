import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [name, setName] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newErrors = {};
        if (!name) newErrors.name = true;
        if (!email) newErrors.email = true;
        if (!projectTitle) newErrors.projectTitle = true;
        if (!projectDescription) newErrors.projectDescription = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            await axios.post('https://bakare-mailer.onrender.com/api/v1/core/send-email', {
                name,
                projectTitle,
                projectDescription,
                senderEmail: email
            });
            toast.success('Message sent successfully!');
            setErrors({});
            setName('');
            setProjectTitle('');
            setProjectDescription('');
            setEmail('');
        } catch (error) {
            toast.error('Failed to send message.');
        }
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
        if (event.target.value) {
            setErrors(prevErrors => ({ ...prevErrors, [event.target.name]: false }));
        }
    };

    return (
        <section id="contact" className="contact">
            <h2>Do you have a project we can work on together?</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={name}
                    onChange={handleInputChange(setName)}
                    className={errors.name ? 'error' : ''}
                />
                <input
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={handleInputChange(setEmail)}
                    className={errors.email ? 'error' : ''}
                />
                <input
                    type="text"
                    name="projectTitle"
                    placeholder="Project Title"
                    value={projectTitle}
                    onChange={handleInputChange(setProjectTitle)}
                    className={errors.projectTitle ? 'error' : ''}
                />
                <textarea
                    name="projectDescription"
                    placeholder="Project Description"
                    value={projectDescription}
                    maxLength={10000}
                    onChange={handleInputChange(setProjectDescription)}
                    className={errors.projectDescription ? 'error' : ''}
                ></textarea>
                <button type="submit">Send Message</button>
            </form>
            <ToastContainer position="top-right" autoClose={5000} />
        </section>
    );
};

export default Contact;
