import React from 'react';
import '../styles/BirthdayPopup.css';

const BirthdayPopup = ({ message, onClose }) => {
  return (
    <div className="birthday-popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>🎉 {message} 🎉</h2>
      </div>
    </div>
  );
};

export default BirthdayPopup;
